export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const LOGOUT = "LOGOUT";
export const CREATE_VERIFY_DATA_SUCCESS = "RETRIEVE_ROLE_SUCCESS";

export const RETRIEVE_ROLE_SUCCESS = "RETRIEVE_ROLE_SUCCESS";
export const RETRIEVE_ROLE_FAIL = "RETRIEVE_ROLE_FAIL";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";
export const RETRIEVE_USER_SUCCESS = "RETRIEVE_USER_SUCCESS";
export const RETRIEVE_USER_FAIL = "RETRIEVE_USER_FAIL";
export const RESET_USER_SUCCESS = "RESET_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const CREATE_AKTIFITAS_SUCCESS = "CREATE_AKTIFITAS_SUCCESS";
export const CREATE_AKTIFITAS_FAIL = "CREATE_AKTIFITAS_FAIL";
export const RETRIEVE_AKTIFITAS_SUCCESS = "RETRIEVE_AKTIFITAS_SUCCESS";
export const RETRIEVE_AKTIFITAS_FAIL = "RETRIEVE_AKTIFITAS_FAIL";
export const RESET_AKTIFITAS_SUCCESS = "RESET_AKTIFITAS_SUCCESS";
export const UPDATE_AKTIFITAS_SUCCESS = "UPDATE_AKTIFITAS_SUCCESS";
export const UPDATE_AKTIFITAS_FAIL = "UPDATE_AKTIFITAS_FAIL";
export const DELETE_AKTIFITAS_SUCCESS = "DELETE_AKTIFITAS_SUCCESS";
export const DELETE_AKTIFITAS_FAIL = "DELETE_AKTIFITAS_FAIL";

export const CREATE_NOTIFIKASI_SUCCESS = "CREATE_NOTIFIKASI_SUCCESS";
export const CREATE_NOTIFIKASI_FAIL = "CREATE_NOTIFIKASI_FAIL";
export const RETRIEVE_NOTIFIKASI_SUCCESS = "RETRIEVE_NOTIFIKASI_SUCCESS";
export const RETRIEVE_NOTIFIKASI_FAIL = "RETRIEVE_NOTIFIKASI_FAIL";
export const RESET_NOTIFIKASI_SUCCESS = "RESET_NOTIFIKASI_SUCCESS";
export const UPDATE_NOTIFIKASI_SUCCESS = "UPDATE_NOTIFIKASI_SUCCESS";
export const UPDATE_NOTIFIKASI_FAIL = "UPDATE_NOTIFIKASI_FAIL";
export const DELETE_NOTIFIKASI_SUCCESS = "DELETE_NOTIFIKASI_SUCCESS";
export const DELETE_NOTIFIKASI_FAIL = "DELETE_NOTIFIKASI_FAIL";

export const CREATE_SETTING_SUCCESS = "CREATE_SETTING_SUCCESS";
export const CREATE_SETTING_FAIL = "CREATE_SETTING_FAIL";
export const RETRIEVE_SETTING_SUCCESS = "RETRIEVE_SETTING_SUCCESS";
export const RETRIEVE_SETTING_FAIL = "RETRIEVE_SETTING_FAIL";
export const RESET_SETTING_SUCCESS = "RESET_SETTING_SUCCESS";
export const UPDATE_SETTING_SUCCESS = "UPDATE_SETTING_SUCCESS";
export const UPDATE_SETTING_FAIL = "UPDATE_SETTING_FAIL";
export const DELETE_SETTING_SUCCESS = "DELETE_SETTING_SUCCESS";
export const DELETE_SETTING_FAIL = "DELETE_SETTING_FAIL";

export const CREATE_BASEMAP_SUCCESS = "CREATE_BASEMAP_SUCCESS";
export const CREATE_BASEMAP_FAIL = "CREATE_BASEMAP_FAIL";
export const RETRIEVE_BASEMAP_SUCCESS = "RETRIEVE_BASEMAP_SUCCESS";
export const RETRIEVE_BASEMAP_FAIL = "RETRIEVE_BASEMAP_FAIL";
export const RESET_BASEMAP_SUCCESS = "RESET_BASEMAP_SUCCESS";
export const UPDATE_BASEMAP_SUCCESS = "UPDATE_BASEMAP_SUCCESS";
export const UPDATE_BASEMAP_FAIL = "UPDATE_BASEMAP_FAIL";
export const DELETE_BASEMAP_SUCCESS = "DELETE_BASEMAP_SUCCESS";
export const DELETE_BASEMAP_FAIL = "DELETE_BASEMAP_FAIL";

export const CREATE_ANGGOTA_SUCCESS = "CREATE_ANGGOTA_SUCCESS";
export const CREATE_ANGGOTA_FAIL = "CREATE_ANGGOTA_FAIL";
export const RETRIEVE_ANGGOTA_SUCCESS = "RETRIEVE_ANGGOTA_SUCCESS";
export const RETRIEVE_ANGGOTA_FAIL = "RETRIEVE_ANGGOTA_FAIL";
export const RESET_ANGGOTA_SUCCESS = "RESET_ANGGOTA_SUCCESS";
export const UPDATE_ANGGOTA_SUCCESS = "UPDATE_ANGGOTA_SUCCESS";
export const UPDATE_ANGGOTA_FAIL = "UPDATE_ANGGOTA_FAIL";
export const DELETE_ANGGOTA_SUCCESS = "DELETE_ANGGOTA_SUCCESS";
export const DELETE_ANGGOTA_FAIL = "DELETE_ANGGOTA_FAIL";

export const CREATE_PENGATURAN_RADIUS_SUCCESS =
  "CREATE_PENGATURAN_RADIUS_SUCCESS";
export const CREATE_PENGATURAN_RADIUS_FAIL = "CREATE_PENGATURAN_RADIUS_FAIL";
export const RETRIEVE_PENGATURAN_RADIUS_SUCCESS =
  "RETRIEVE_PENGATURAN_RADIUS_SUCCESS";
export const RETRIEVE_PENGATURAN_RADIUS_FAIL =
  "RETRIEVE_PENGATURAN_RADIUS_FAIL";
export const RESET_PENGATURAN_RADIUS_SUCCESS =
  "RESET_PENGATURAN_RADIUS_SUCCESS";
export const UPDATE_PENGATURAN_RADIUS_SUCCESS =
  "UPDATE_PENGATURAN_RADIUS_SUCCESS";
export const UPDATE_PENGATURAN_RADIUS_FAIL = "UPDATE_PENGATURAN_RADIUS_FAIL";
export const DELETE_PENGATURAN_RADIUS_SUCCESS =
  "DELETE_PENGATURAN_RADIUS_SUCCESS";
export const DELETE_PENGATURAN_RADIUS_FAIL = "DELETE_PENGATURAN_RADIUS_FAIL";

export const CREATE_KONVERSI_POIN_SUCCESS = "CREATE_KONVERSI_POIN_SUCCESS";
export const CREATE_KONVERSI_POIN_FAIL = "CREATE_KONVERSI_POIN_FAIL";
export const RETRIEVE_KONVERSI_POIN_SUCCESS = "RETRIEVE_KONVERSI_POIN_SUCCESS";
export const RETRIEVE_KONVERSI_POIN_FAIL = "RETRIEVE_KONVERSI_POIN_FAIL";
export const RESET_KONVERSI_POIN_SUCCESS = "RESET_KONVERSI_POIN_SUCCESS";
export const UPDATE_KONVERSI_POIN_SUCCESS = "UPDATE_KONVERSI_POIN_SUCCESS";
export const UPDATE_KONVERSI_POIN_FAIL = "UPDATE_KONVERSI_POIN_FAIL";
export const DELETE_KONVERSI_POIN_SUCCESS = "DELETE_KONVERSI_POIN_SUCCESS";
export const DELETE_KONVERSI_POIN_FAIL = "DELETE_KONVERSI_POIN_FAIL";

export const CREATE_POJOK_EDUKASI_SUCCESS = "CREATE_POJOK_EDUKASI_SUCCESS";
export const CREATE_POJOK_EDUKASI_FAIL = "CREATE_POJOK_EDUKASI_FAIL";
export const RETRIEVE_POJOK_EDUKASI_SUCCESS = "RETRIEVE_POJOK_EDUKASI_SUCCESS";
export const RETRIEVE_POJOK_EDUKASI_FAIL = "RETRIEVE_POJOK_EDUKASI_FAIL";
export const RESET_POJOK_EDUKASI_SUCCESS = "RESET_POJOK_EDUKASI_SUCCESS";
export const UPDATE_POJOK_EDUKASI_SUCCESS = "UPDATE_POJOK_EDUKASI_SUCCESS";
export const UPDATE_POJOK_EDUKASI_FAIL = "UPDATE_POJOK_EDUKASI_FAIL";
export const DELETE_POJOK_EDUKASI_SUCCESS = "DELETE_POJOK_EDUKASI_SUCCESS";
export const DELETE_POJOK_EDUKASI_FAIL = "DELETE_POJOK_EDUKASI_FAIL";

export const CREATE_PIRING_BERBAGI_SUCCESS = "CREATE_PIRING_BERBAGI_SUCCESS";
export const CREATE_PIRING_BERBAGI_FAIL = "CREATE_PIRING_BERBAGI_FAIL";
export const RETRIEVE_PIRING_BERBAGI_SUCCESS =
  "RETRIEVE_PIRING_BERBAGI_SUCCESS";
export const RETRIEVE_PIRING_BERBAGI_FAIL = "RETRIEVE_PIRING_BERBAGI_FAIL";
export const RESET_PIRING_BERBAGI_SUCCESS = "RESET_PIRING_BERBAGI_SUCCESS";
export const UPDATE_PIRING_BERBAGI_SUCCESS = "UPDATE_PIRING_BERBAGI_SUCCESS";
export const UPDATE_PIRING_BERBAGI_FAIL = "UPDATE_PIRING_BERBAGI_FAIL";
export const DELETE_PIRING_BERBAGI_SUCCESS = "DELETE_PIRING_BERBAGI_SUCCESS";
export const DELETE_PIRING_BERBAGI_FAIL = "DELETE_PIRING_BERBAGI_FAIL";

export const CREATE_BANK_KOMPOS_SUCCESS = "CREATE_BANK_KOMPOS_SUCCESS";
export const CREATE_BANK_KOMPOS_FAIL = "CREATE_BANK_KOMPOS_FAIL";
export const RETRIEVE_BANK_KOMPOS_SUCCESS = "RETRIEVE_BANK_KOMPOS_SUCCESS";
export const RETRIEVE_BANK_KOMPOS_FAIL = "RETRIEVE_BANK_KOMPOS_FAIL";
export const RESET_BANK_KOMPOS_SUCCESS = "RESET_BANK_KOMPOS_SUCCESS";
export const UPDATE_BANK_KOMPOS_SUCCESS = "UPDATE_BANK_KOMPOS_SUCCESS";
export const UPDATE_BANK_KOMPOS_FAIL = "UPDATE_BANK_KOMPOS_FAIL";
export const DELETE_BANK_KOMPOS_SUCCESS = "DELETE_BANK_KOMPOS_SUCCESS";
export const DELETE_BANK_KOMPOS_FAIL = "DELETE_BANK_KOMPOS_FAIL";

export const CREATE_BERDIKARI_MART_SUCCESS = "CREATE_BERDIKARI_MART_SUCCESS";
export const CREATE_BERDIKARI_MART_FAIL = "CREATE_BERDIKARI_MART_FAIL";
export const RETRIEVE_BERDIKARI_MART_SUCCESS =
  "RETRIEVE_BERDIKARI_MART_SUCCESS";
export const RETRIEVE_BERDIKARI_MART_FAIL = "RETRIEVE_BERDIKARI_MART_FAIL";
export const RESET_BERDIKARI_MART_SUCCESS = "RESET_BERDIKARI_MART_SUCCESS";
export const UPDATE_BERDIKARI_MART_SUCCESS = "UPDATE_BERDIKARI_MART_SUCCESS";
export const UPDATE_BERDIKARI_MART_FAIL = "UPDATE_BERDIKARI_MART_FAIL";
export const DELETE_BERDIKARI_MART_SUCCESS = "DELETE_BERDIKARI_MART_SUCCESS";
export const DELETE_BERDIKARI_MART_FAIL = "DELETE_BERDIKARI_MART_FAIL";

export const CREATE_FOOD_TYPE_SUCCESS = "CREATE_FOOD_TYPE_SUCCESS";
export const CREATE_FOOD_TYPE_FAIL = "CREATE_FOOD_TYPE_FAIL";
export const RETRIEVE_FOOD_TYPE_SUCCESS = "RETRIEVE_FOOD_TYPE_SUCCESS";
export const RETRIEVE_FOOD_TYPE_FAIL = "RETRIEVE_FOOD_TYPE_FAIL";
export const RESET_FOOD_TYPE_SUCCESS = "RESET_FOOD_TYPE_SUCCESS";
export const UPDATE_FOOD_TYPE_SUCCESS = "UPDATE_FOOD_TYPE_SUCCESS";
export const UPDATE_FOOD_TYPE_FAIL = "UPDATE_FOOD_TYPE_FAIL";
export const DELETE_FOOD_TYPE_SUCCESS = "DELETE_FOOD_TYPE_SUCCESS";
export const DELETE_FOOD_TYPE_FAIL = "DELETE_FOOD_TYPE_FAIL";

export const CREATE_DELIVER_TYPE_SUCCESS = "CREATE_DELIVER_TYPE_SUCCESS";
export const CREATE_DELIVER_TYPE_FAIL = "CREATE_DELIVER_TYPE_FAIL";
export const RETRIEVE_DELIVER_TYPE_SUCCESS = "RETRIEVE_DELIVER_TYPE_SUCCESS";
export const RETRIEVE_DELIVER_TYPE_FAIL = "RETRIEVE_DELIVER_TYPE_FAIL";
export const RESET_DELIVER_TYPE_SUCCESS = "RESET_DELIVER_TYPE_SUCCESS";
export const UPDATE_DELIVER_TYPE_SUCCESS = "UPDATE_DELIVER_TYPE_SUCCESS";
export const UPDATE_DELIVER_TYPE_FAIL = "UPDATE_DELIVER_TYPE_FAIL";
export const DELETE_DELIVER_TYPE_SUCCESS = "DELETE_DELIVER_TYPE_SUCCESS";
export const DELETE_DELIVER_TYPE_FAIL = "DELETE_DELIVER_TYPE_FAIL";

export const CREATE_ARTICLE_CATEGORIES_SUCCESS = "CREATE_ARTICLE_CATEGORIES_SUCCESS";
export const CREATE_ARTICLE_CATEGORIES_FAIL = "CREATE_ARTICLE_CATEGORIES_FAIL";
export const RETRIEVE_ARTICLE_CATEGORIES_SUCCESS = "RETRIEVE_ARTICLE_CATEGORIES_SUCCESS";
export const RETRIEVE_ARTICLE_CATEGORIES_FAIL = "RETRIEVE_ARTICLE_CATEGORIES_FAIL";
export const RESET_ARTICLE_CATEGORIES_SUCCESS = "RESET_ARTICLE_CATEGORIES_SUCCESS";
export const UPDATE_ARTICLE_CATEGORIES_SUCCESS = "UPDATE_ARTICLE_CATEGORIES_SUCCESS";
export const UPDATE_ARTICLE_CATEGORIES_FAIL = "UPDATE_ARTICLE_CATEGORIES_FAIL";
export const DELETE_ARTICLE_CATEGORIES_SUCCESS = "DELETE_ARTICLE_CATEGORIES_SUCCESS";
export const DELETE_ARTICLE_CATEGORIES_FAIL = "DELETE_ARTICLE_CATEGORIES_FAIL";

export const CREATE_ARTICLE_TYPES_SUCCESS = "CREATE_ARTICLE_TYPES_SUCCESS";
export const CREATE_ARTICLE_TYPES_FAIL = "CREATE_ARTICLE_TYPES_FAIL";
export const RETRIEVE_ARTICLE_TYPES_SUCCESS = "RETRIEVE_ARTICLE_TYPES_SUCCESS";
export const RETRIEVE_ARTICLE_TYPES_FAIL = "RETRIEVE_ARTICLE_TYPES_FAIL";
export const RESET_ARTICLE_TYPES_SUCCESS = "RESET_ARTICLE_TYPES_SUCCESS";
export const UPDATE_ARTICLE_TYPES_SUCCESS = "UPDATE_ARTICLE_TYPES_SUCCESS";
export const UPDATE_ARTICLE_TYPES_FAIL = "UPDATE_ARTICLE_TYPES_FAIL";
export const DELETE_ARTICLE_TYPES_SUCCESS = "DELETE_ARTICLE_TYPES_SUCCESS";
export const DELETE_ARTICLE_TYPES_FAIL = "DELETE_ARTICLE_TYPES_FAIL";

export const CREATE_SATUAN_SUCCESS = "CREATE_SATUAN_SUCCESS";
export const CREATE_SATUAN_FAIL = "CREATE_SATUAN_FAIL";
export const RETRIEVE_SATUAN_SUCCESS = "RETRIEVE_SATUAN_SUCCESS";
export const RETRIEVE_SATUAN_FAIL = "RETRIEVE_SATUAN_FAIL";
export const RESET_SATUAN_SUCCESS = "RESET_SATUAN_SUCCESS";
export const UPDATE_SATUAN_SUCCESS = "UPDATE_SATUAN_SUCCESS";
export const UPDATE_SATUAN_FAIL = "UPDATE_SATUAN_FAIL";
export const DELETE_SATUAN_SUCCESS = "DELETE_SATUAN_SUCCESS";
export const DELETE_SATUAN_FAIL = "DELETE_SATUAN_FAIL";

export const CREATE_FAQ_SUCCESS = "CREATE_FAQ_SUCCESS";
export const CREATE_FAQ_FAIL = "CREATE_FAQ_FAIL";
export const RETRIEVE_FAQ_SUCCESS = "RETRIEVE_FAQ_SUCCESS";
export const RETRIEVE_FAQ_FAIL = "RETRIEVE_FAQ_FAIL";
export const RESET_FAQ_SUCCESS = "RESET_FAQ_SUCCESS";
export const UPDATE_FAQ_SUCCESS = "UPDATE_FAQ_SUCCESS";
export const UPDATE_FAQ_FAIL = "UPDATE_FAQ_FAIL";
export const DELETE_FAQ_SUCCESS = "DELETE_FAQ_SUCCESS";
export const DELETE_FAQ_FAIL = "DELETE_FAQ_FAIL";

export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
export const CREATE_CONTACT_FAIL = "CREATE_CONTACT_FAIL";
export const RETRIEVE_CONTACT_SUCCESS = "RETRIEVE_CONTACT_SUCCESS";
export const RETRIEVE_CONTACT_FAIL = "RETRIEVE_CONTACT_FAIL";
export const RESET_CONTACT_SUCCESS = "RESET_CONTACT_SUCCESS";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const UPDATE_CONTACT_FAIL = "UPDATE_CONTACT_FAIL";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const DELETE_CONTACT_FAIL = "DELETE_CONTACT_FAIL";

export const CREATE_ASAL_MAKANAN_SUCCESS = "CREATE_ASAL_MAKANAN_SUCCESS";
export const CREATE_ASAL_MAKANAN_FAIL = "CREATE_ASAL_MAKANAN_FAIL";
export const RETRIEVE_ASAL_MAKANAN_SUCCESS = "RETRIEVE_ASAL_MAKANAN_SUCCESS";
export const RETRIEVE_ASAL_MAKANAN_FAIL = "RETRIEVE_ASAL_MAKANAN_FAIL";
export const RESET_ASAL_MAKANAN_SUCCESS = "RESET_ASAL_MAKANAN_SUCCESS";
export const UPDATE_ASAL_MAKANAN_SUCCESS = "UPDATE_ASAL_MAKANAN_SUCCESS";
export const UPDATE_ASAL_MAKANAN_FAIL = "UPDATE_ASAL_MAKANAN_FAIL";
export const DELETE_ASAL_MAKANAN_SUCCESS = "DELETE_ASAL_MAKANAN_SUCCESS";
export const DELETE_ASAL_MAKANAN_FAIL = "DELETE_ASAL_MAKANAN_FAIL";

export const CREATE_AGREMEENT_SUCCESS = "CREATE_AGREMEENT_SUCCESS";
export const CREATE_AGREMEENT_FAIL = "CREATE_AGREMEENT_FAIL";
export const RETRIEVE_AGREMEENT_SUCCESS = "RETRIEVE_AGREMEENT_SUCCESS";
export const RETRIEVE_AGREMEENT_FAIL = "RETRIEVE_AGREMEENT_FAIL";
export const RESET_AGREMEENT_SUCCESS = "RESET_AGREMEENT_SUCCESS";
export const UPDATE_AGREMEENT_SUCCESS = "UPDATE_AGREMEENT_SUCCESS";
export const UPDATE_AGREMEENT_FAIL = "UPDATE_AGREMEENT_FAIL";
export const DELETE_AGREMEENT_SUCCESS = "DELETE_AGREMEENT_SUCCESS";
export const DELETE_AGREMEENT_FAIL = "DELETE_AGREMEENT_FAIL";

export const CREATE_STATISTIK_SUCCESS = "CREATE_STATISTIK_SUCCESS";
export const CREATE_STATISTIK_FAIL = "CREATE_STATISTIK_FAIL";
export const RETRIEVE_STATISTIK_SUCCESS = "RETRIEVE_STATISTIK_SUCCESS";
export const RETRIEVE_STATISTIK_FAIL = "RETRIEVE_STATISTIK_FAIL";
export const RESET_STATISTIK_SUCCESS = "RESET_STATISTIK_SUCCESS";
export const UPDATE_STATISTIK_SUCCESS = "UPDATE_STATISTIK_SUCCESS";
export const UPDATE_STATISTIK_FAIL = "UPDATE_STATISTIK_FAIL";
export const DELETE_STATISTIK_SUCCESS = "DELETE_STATISTIK_SUCCESS";
export const DELETE_STATISTIK_FAIL = "DELETE_STATISTIK_FAIL";

export const CREATE_PIRING_TRANSAKSI_SUCCESS = "CREATE_PIRING_TRANSAKSI_SUCCESS";
export const CREATE_PIRING_TRANSAKSI_FAIL = "CREATE_PIRING_TRANSAKSI_FAIL";
export const RETRIEVE_PIRING_TRANSAKSI_SUCCESS = "RETRIEVE_PIRING_TRANSAKSI_SUCCESS";
export const RETRIEVE_PIRING_TRANSAKSI_FAIL = "RETRIEVE_PIRING_TRANSAKSI_FAIL";
export const RESET_PIRING_TRANSAKSI_SUCCESS = "RESET_PIRING_TRANSAKSI_SUCCESS";
export const UPDATE_PIRING_TRANSAKSI_SUCCESS = "UPDATE_PIRING_TRANSAKSI_SUCCESS";
export const UPDATE_PIRING_TRANSAKSI_FAIL = "UPDATE_PIRING_TRANSAKSI_FAIL";
export const DELETE_PIRING_TRANSAKSI_SUCCESS = "DELETE_PIRING_TRANSAKSI_SUCCESS";
export const DELETE_PIRING_TRANSAKSI_FAIL = "DELETE_PIRING_TRANSAKSI_FAIL";

const environment = {
  baseUrl: "http://localhost:3008/ngupahan/", //"http://localhost:3000",
  api: "http://localhost:8080/", //"http://localhost:8080/api",
};

if (process.env.REACT_APP_ENV === "development") {
  environment.baseUrl = "http://localhost:3008/ngupahan/";
  environment.api = "http://localhost:8080/";
}

if (process.env.REACT_APP_ENV === "staging") {
  environment.baseUrl = "http://sta.techlab.id";
}

if (process.env.REACT_APP_ENV === "production") {
  environment.baseUrl = "https://ngupahan.id/";
  environment.api = "https://ngupahan.id/ngupahan-api/";
}

export default environment;

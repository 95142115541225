import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
//import notifikasi from "./notifikasi";
import role from "./role";
//import setting from "./setting";
import user from "./user";
import pojok_edukasi from "./pojok_edukasi";
import piring_berbagi from "./piring_berbagi";
import bank_kompos from "./bank_kompos";
import berdikari_mart from "./berdikari_mart";
import pengaturan_radius from "./pengaturan_radius";
import konversi_poin from "./konversi_poin";
import deliver_type from "./deliver_type";
import food_type from "./food_type";
import satuan from "./satuan";
import article_categories from "./article_categories";
import article_types from "./article_types";
import daftar from "./daftar";
import faq from "./faq";
import contact from "./contact";
import agremeent from "./agremeent";
import asal_makanan from "./asal_makanan";
import statistik from "./statistik";
import piring_transaksi from "./piring_transaksi";

export default combineReducers({
  auth,
  message,
  bank_kompos,
  daftar,
  deliver_type,
  food_type,
  pojok_edukasi,
  piring_berbagi,
  berdikari_mart,
  pengaturan_radius,
  konversi_poin,
  role,
  user,
  satuan,
  article_categories,
  article_types,
  contact,
  faq,
  agremeent,
  asal_makanan,
  statistik,
  piring_transaksi,
});
